<template>
    <v-row no-gutters justify="center">
        <v-col cols="10" sm="8" md="6" lg="6" xl="6">
            <template v-if="!isViewReady && !errorBadRequest && !errorUnauthorized && !errorForbidden && !errorNotFound">
                <v-row style="height: 100%" align="center" justify="center">
                    <div class="app-splash-loader"></div>
                </v-row>
            </template>
            <template v-if="errorBadRequest">
                <v-row justify="center" class="py-5 mt-8">
                    <v-col>
                        <h1 class="text-h4 font-weight-light text-center">Bad Request</h1>
                        <p class="text-center mt-10">Something unexpected happened.</p>
                        <!-- <p class="text-center mt-10">Our support team has been alerted and we are working to resolve the issue.</p>
                        <p class="text-center mt-10">Please try the request again later.</p> -->
                        <!-- TODO: incident link to track progress -->
                    </v-col>
                </v-row>
            </template>
            <template v-if="errorUnauthorized">
                <v-row justify="center" class="py-5 mt-8">
                    <v-col>
                        <h1 class="text-h4 font-weight-light text-center">Unauthorized</h1>
                        <p class="text-center mt-10">The resource you requested is not available.</p>
                        <p class="text-center mt-10">If you received an invitation link, follow that link to continue.</p>
                    </v-col>
                </v-row>
            </template>
            <template v-if="errorForbidden">
                <v-row justify="center" class="py-5 mt-8">
                    <v-col>
                        <h1 class="text-h4 font-weight-light text-center">Forbidden</h1>
                        <p class="text-center mt-10">The resource you requested is not available.</p>
                        <p class="text-center mt-10">If you received an invitation link, follow that link to continue.</p>
                    </v-col>
                </v-row>
            </template>
            <template v-if="errorNotFound">
                <v-row justify="center" class="py-5 mt-8">
                    <v-col>
                        <h1 class="text-h4 font-weight-light text-center">Not Found</h1>
                        <p class="text-center mt-10">The resource you requested is not available.</p>
                        <!-- TODO: should we use a support-specific page here? or just let user find it from main page? or add a query parameter to main page indicating user needs help, so it might show a popup for that to get them started in the right direction? -->
                        <p class="text-center mt-10">For assistance, visit <a :href="mainWebsiteURL">LibertyBase</a></p>
                        <!-- <p class="text-center mt-10">Our support team has been alerted and we are working to resolve the issue.</p>
                        <p class="text-center mt-10">Please try the request again later.</p> -->
                        <!-- TODO: incident link to track progress -->
                    </v-col>
                </v-row>
            </template>
            <template v-if="errorUnavailable">
                <v-row justify="center" class="py-5 mt-8">
                    <v-col>
                        <h1 class="text-h4 font-weight-light text-center">Server Error</h1>
                        <p class="text-center mt-10">The resource you requested is temporarily unavailable. Please try again later.</p>
                    </v-col>
                </v-row>
            </template>
            <template v-if="isViewReady">
                <template v-if="mode === 'entry'">
                <ImageBox :alias="brandprofile" intent="logotype" mode="light" :max-height="48" contain v-if="brandprofile" class="mt-8"/>

                <h1 class="mt-8 text-center" v-if="title">{{ title }}</h1>
                <!-- TODO: replace below loop with whatever the render function deides to do... don't render directly from the form here -->
                <v-card class="mt-8 px-4 py-4">
                    <v-form onSubmit="return false;" @keyup.enter.native="nextStep">
                    <div v-for="(item, idx) in view" v-bind:key="idx" class="mt-4 mb-4">
                        <template v-if="item.type === 'input'">
                            <p v-if="input[item.key].prompt" class="font-weight-bold">{{ input[item.key].prompt }}</p>
                            <p class="text-caption" v-if="input[item.key].notice">{{ input[item.key].notice }}</p>
                            <template v-if="input[item.key].type === 'select'">
                                <v-select outlined dense v-model="entry[item.key]" :ref="input[item.key].ref" :label="input[item.key].label" :multiple="input[item.key].is_array" :items="input[item.key].select.choices" :hint="input[item.key].helptext" :color="primaryColor" persistent-hint @focus="activate(item.key)"></v-select>
                            </template>
                            <template v-if="input[item.key].type === 'text'">
                                <v-text-field outlined dense v-model="entry[item.key]" :ref="input[item.key].ref" :label="input[item.key].label" :hint="input[item.key].helptext" :color="primaryColor" persistent-hint @focus="activate(item.key)"></v-text-field>
                            </template>
                            <template v-if="input[item.key].type === 'email'">
                                <v-text-field outlined dense v-model="entry[item.key]" :ref="input[item.key].ref" :label="input[item.key].label" :hint="input[item.key].helptext" :color="primaryColor" persistent-hint @focus="activate(item.key)"></v-text-field>
                            </template>
                            <template v-if="input[item.key].type === 'url'">
                                <v-text-field outlined dense v-model="entry[item.key]" :ref="input[item.key].ref" :label="input[item.key].label" :hint="input[item.key].helptext" :color="primaryColor" persistent-hint @focus="activate(item.key)"></v-text-field>
                            </template>
                            <template v-if="input[item.key].type === 'numeric'">
                                Numeric...
                            </template>
                            <!-- expandable help text -->
                            <!-- <p class="text-caption mt-0 pt-0" v-if="input[item.key].helptext">
                                More information
                                <v-btn icon small>
                                    <font-awesome-icon :icon="['fas', 'caret-down']" />
                                </v-btn>
                                {{ input[item.key].helptext }}
                            </p> -->
                        </template>
                        <template v-if="item.type === 'hr'">
                            <hr class="my-8" style="border: 2px solid black"/>
                        </template>
                    </div>
                    </v-form>
                    <v-card-actions>
                        <v-spacer/>
                        <!-- TODO: use configured color from account settings or form settings, or default purple -->
                        <!-- TODO: check pagination settings, OPEN QUESTION do we want to change button text when this is the last page (submit isntead of continue?) -->
                        <v-btn :style="primaryButtonStyle" @click="submitInput">Continue</v-btn> <!-- previously color="purple white--text" -->
                    </v-card-actions>
                </v-card>
                </template>
                <template v-if="Array.isArray(faults) && faults.length > 0">
                    <p class="red--text mt-8">
                        Please correct the following errors:
                    </p>
                    <ul>
                        <li v-for="(item, idx2) in faults" v-bind:key="idx2" class="red--text">
                            <template v-if="item.type === 'REQUIRED'">
                                {{ input[item.key].label }} is required
                            </template>
                            <template v-else-if="item.type === 'EXPECTED_ONE'">
                                {{ input[item.key].label }} should be only a single item
                            </template>
                            <template v-else-if="item.type === 'EXPECTED_ARRAY'">
                                {{ input[item.key].label }} should be a list
                            </template>
                            <template v-else-if="item.type === 'ARRAY_ITEM_NULL'">
                                Item {{ item.index + 1 }} of {{ input[item.key].label }} is empty
                            </template>
                            <template v-else>
                                <!-- TODO: is there a better way to handle other kinds of errors? maybe say 'here is the error code from the server' ? -->
                                {{ input[item.key].label }} error: {{ item.type }}
                            </template>
                        </li>
                    </ul>
                </template>
                <template v-if="mode === 'confirm'">
                    <ImageBox :alias="brandprofile" intent="logotype" mode="light" :max-height="48" contain v-if="brandprofile" class="mt-8"/>
                    <h1 class="mt-8 text-center" v-if="confirm.headline">{{ confirm.headline }}</h1>
                    <p class="mt-8 text-center" v-if="confirm.message" v-html="confirmMessageHtml"></p>
                    <p class="mt-8 text-center"><font-awesome-icon :icon="['fas', 'check-circle']" :color="primaryColor" size="3x"/></p>
                </template>
                <!-- TODO: brand company name, other footer message -->
            </template>
            <v-expansion-panels class="mt-8" v-ifdev>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <span>
                        View <font-awesome-icon :icon="['far', 'code']" class="grey--text"/>
                        </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <pre>{{ JSON.stringify(this.view, null, 2) }}</pre>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <span>
                        Input Specification <font-awesome-icon :icon="['far', 'code']" class="grey--text"/>
                        </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <pre>{{ JSON.stringify(this.input, null, 2) }}</pre>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <span>
                        Entry <font-awesome-icon :icon="['far', 'code']" class="grey--text"/>
                        </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <pre>{{ JSON.stringify(this.entry, null, 2) }}</pre>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <span>
                        Faults <font-awesome-icon :icon="['far', 'code']" class="grey--text"/>
                        </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <pre>{{ JSON.stringify(this.faults, null, 2) }}</pre>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-col>
    </v-row>
</template>
<style lang="css">
.app-splash-loader {
    border: 4px solid #e1bee7; /* purple lighten-4 */
    border-top: 4px solid #9c27b0; /* purple */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1.0s linear infinite;
    margin: auto;
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
</style>
<script>
import { mapState, mapGetters } from 'vuex';
// import { client } from '@/client';
import ImageBox from '@/components/ImageBox.vue';

export default {
    components: {
        ImageBox,
    },
    data: () => ({
        isViewReady: false,
        inviteId: null, // only set after validating
        interactionId: null, // only set after validating
        mode: null, // 'entry' or 'confirm'
        title: null,
        view: null,
        input: null,
        entry: null, // {} key (input id) => value (user input)
        active: null, // when an input is focused or active, this contains the input key
        faults: null, // []
        faultMap: null, // {}
        confirm: null, // { headline, message } , only when mode === 'confirm'
        // loading: true,
        errorBadRequest: false, // when server reports we made a bad request (UI error)
        errorUnauthorized: false,
        errorForbidden: false,
        errorNotFound: false, // when someone arrives here with an invalid link (missing or unknown invite id)
        errorUnavailable: false,
        // brandprofile
        brandprofile: null, // the brandprofile alias, only if defined by the form
    }),
    computed: {
        ...mapState({
            // session: (state) => state.session,
            // brand: (state) => state.brand,
            palette: (state) => state.palette, // from brandprofile, only if brandprofile is set and a matching palette was found at brandprofile cdn
        }),
        ...mapGetters({
            isLoading: 'isLoading',
            brandName: 'brandName',
            primaryColor: 'primaryColor',
            primaryTextColor: 'primaryTextColor',
            accentColor: 'accentColor',
            cardTitleBarTextStyle: 'cardTitleBarTextStyle',
            cardTitleBarStyle: 'cardTitleBarStyle',
            primaryButtonStyle: 'primaryButtonStyle',
            primaryIconStyle: 'primaryIconStyle',
        }),
        // ...mapState({
        //     isAuthenticatedReady: (state) => state.isReady,
        //     session: (state) => state.session,
        // }),
        // isAuthenticated() {
        //     return this.session.isAuthenticated;
        // },
        confirmMessageHtml() {
            if (typeof this.confirm?.message === 'string') {
                const html = '<p>' + this.confirm.message.split('\n').join('</p><p>') + '</p>'; // eslint-disable-line prefer-template
                return html;
            }
            return '';
        },
        mainWebsiteURL() {
            return process.env.VUE_APP_MAIN_WEBSITE_URL ?? 'https://libertybase.io';
        },
    },
    watch: {
        brandprofile(newValue, oldValue) {
            if (newValue && newValue !== oldValue) {
                this.loadBrand();
                this.loadPalette();
            }
        },
    },
    methods: {
        async init() {
            // an invite id is required in all cases
            if (typeof this.$route.params.inviteId !== 'string' || this.$route.params.inviteId.length === 0) {
                this.errorNotFound = true;
                return;
            }
            this.inviteId = this.$route.params.inviteId;
            // if an interaction is already started, continue the existing interaction; otherwise start a new one
            if (this.$route.query.i) {
                await this.checkInteraction(this.$route.query.i);
            } else {
                await this.startInteraction();
            }
            this.isViewReady = true;
        },
        /**
         * Precondition: this.inviteId must be set to a valid invite id
         */
        async checkInteraction(interactionId) {
            try {
                this.$store.commit('loading', { checkInteraction: true });
                const response = await this.$client.interaction(interactionId).entry.check();
                console.log(`checkInteraction response: ${JSON.stringify(response)}`);
                const {
                    type,
                    invite_id: inviteId,
                    next_route: nextRoute,
                    error,
                } = response;
                console.log(`checkInteraction, invite type ${type}`);
                if (error || type !== 'form-entry' || inviteId !== this.inviteId) {
                    this.errorNotFound = true;
                } else if (nextRoute) {
                    console.log(`checkInteraction nextRoute: ${JSON.stringify(nextRoute)}`);
                    this.$router.replace(nextRoute);
                } else {
                    this.interactionId = interactionId;
                    this.checkEntry();
                }
            } catch (err) {
                if (err.response?.status === 400) {
                    console.log('server reports bad request');
                    this.errorBadRequest = true;
                    // TODO: automatically submit support ticket
                } else if (err.response?.status === 401) {
                    console.log('interaction access requires authorization');
                    this.errorUnauthorized = true;
                    // TODO: help user become authorized via email verification, telephone verification, login, one-time passcode, or whatever is required by the invite
                } else if (err.response?.status === 403) {
                    console.log('interaction not found or access is forbidden');
                    // this.errorNotFound = true; // we show a forbidden error as not found, because we really don't know if the invite wasn't found or if the user is forbidden from accessing it (different than unauthorized)
                    // remove interaction id from url and start a new interaction
                    this.$router.replace({ name: 'form', params: { inviteId: this.$route.params.inviteId } });
                    this.startInteraction();
                } else if (err.response?.status === 404 || err.response?.status === 500) {
                    // 404 error from server means the API wasn't found, which should hopefully be a temporary situation during an upgrade; the API itself never returns 404, it's always 400 (if we didn't send invite id), 401 (if login is required), 403 (for invite not found or not authorized for this user), or 500 (if an exception occurred).
                    console.log('server error');
                    this.errorUnavailable = true;
                } else {
                    console.error('failed to load interaction', err);
                }
            } finally {
                this.$store.commit('loading', { checkInteraction: false });
            }
        },
        /**
         * Precondition: this.inviteId must be set to a valid invite id.
         *
         * Server will create a new interaction and return the id.
         */
        async startInteraction() {
            try {
                this.$store.commit('loading', { startInteraction: true });
                // const response = await this.$client.main().interaction.start({ inviteId: this.inviteId });
                const response = await this.$client.main().invite.check({ id: this.inviteId });
                console.log(`startInteraction response: ${JSON.stringify(response)}`);
                const {
                    type,
                    interaction_id: interactionId,
                    next_route: nextRoute,
                    error,
                } = response;
                console.log(`startInteraction, invite type ${type}`);
                if (error || type !== 'form-entry') {
                    this.errorNotFound = true;
                } else if (nextRoute) {
                    console.log(`startInteraction nextRoute: ${JSON.stringify(nextRoute)}`);
                    this.$router.replace(nextRoute);
                } else {
                    this.interactionId = interactionId;
                    this.$router.replace({ name: 'form', /* this.$route.name, */ params: { inviteId: this.$route.params.inviteId }, query: { i: interactionId } });
                    this.checkEntry(); // the redirect is to the same page + a query parameter, so vue will not reload the component or call lifecycle hooks; we can just continue here
                }
            } catch (err) {
                if (err.response?.status === 400) {
                    console.log('server reports bad request');
                    this.errorBadRequest = true;
                    // TODO: automatically submit support ticket
                } else if (err.response?.status === 401) {
                    console.log('interaction access requires authorization');
                    this.errorUnauthorized = true;
                    // TODO: help user become authorized via email verification, telephone verification, login, one-time passcode, or whatever is required by the invite
                } else if (err.response?.status === 403) {
                    console.log('interaction not found or access is forbidden');
                    this.errorNotFound = true; // we show a forbidden error as not found, because we really don't know if the invite wasn't found or if the user is forbidden from accessing it (different than unauthorized)
                } else if (err.response?.status === 404 || err.response?.status === 500) {
                    // 404 error from server means the API wasn't found, which should hopefully be a temporary situation during an upgrade; the API itself never returns 404, it's always 400 (if we didn't send invite id), 401 (if login is required), 403 (for invite not found or not authorized for this user), or 500 (if an exception occurred).
                    console.log('server error');
                    this.errorUnavailable = true;
                } else {
                    console.error('failed to start interaction', err);
                }
            } finally {
                this.$store.commit('loading', { startInteraction: false });
            }
        },
        /**
         * Fetch entry information from server. Decorate UI with any form-specific or invite-specific images, colors, etc.
         */
        async checkEntry() {
            try {
                this.$store.commit('loading', { checkEntry: true });
                const response = await this.$client.interaction(this.interactionId).entry.check();
                console.log(`checkEntry response: ${JSON.stringify(response)}`);
                this.render(response);
                this.isViewReady = true;
            } catch (err) {
                this.isViewReady = false;
                if (err.response?.status === 403) {
                    console.log('invite not found or access is forbidden');
                    this.errorNotFound = true; // we show a forbidden error as not found, because we really don't know if the invite wasn't found or if the user is forbidden from accessing it (different than unauthorized)
                } else {
                    console.error('failed to load invitation', err);
                }
            } finally {
                this.$store.commit('loading', { checkEntry: false });
            }
        },
        async submitInput() {
            try {
                // clear error flags before we submit
                this.errorBadRequest = false;
                this.errorUnauthorized = false;
                this.errorForbidden = false;
                this.errorNotFound = false;
                this.$store.commit('loading', { submitInput: true });
                // NOTE: entry data will include null values for any optional inputs that were not edited by the user, so server will know user skipped them
                // TODO: only submit the inputs that changed since existing entry data was loaded; if a form has 5 pages and we're on page 3, we don't need to submit all the values for all 5 pages here... just the ones that changed on page 3. server should merge our submission with the existing entry data. might need to keep a copy of entry when we load it so we can compare here.
                const response = await this.$client.interaction(this.interactionId).entry.edit(this.entry);
                console.log(`submitInput response: ${JSON.stringify(response)}`);
                // TODO: response should have next rendering info ... OR it may have errors from the submission... so chek for errors, render that if needed, otherwise render next view
                this.render(response);
                this.isViewReady = true;
            } catch (err) {
                // TODO: display errors... possibly next to inputs
                // this.isViewReady = false;
                if (err.response?.status === 400) {
                    // the request was invalid
                    // TODO: automatically create a support incident because this should not happen -- the fault is either in the user interface or the server, and the development team needs to investigate
                    this.errorBadRequest = true;
                } else if (err.response?.status === 401) {
                    // interaction was not found or user is not authorized for it; we cannot know but either way the only way to continue is to start over with an invitation link
                    this.errorUnauthorized = true;
                } else if (err.response?.status === 403) {
                    // interaction was found but is invalid in some way; the only way to continue is to start over with an invitation link
                    this.errorForbidden = true;
                } else if (err.response?.status === 404) {
                    this.errorNotFound = true;
                } else if (err.response?.status === 500) {
                    // internal server error
                    // TODO: automatically create a support incident because this should not happen -- the fault is in the server
                    this.errorBadRequest = true;
                }
            } finally {
                this.$store.commit('loading', { submitInput: false });
            }
        },
        /**
         * Precondition: values for title, view, input, and entry already set on component data.
         */
        render(context) {
            console.log('render started');
            this.brandprofile = context.brandprofile ?? null;
            this.title = context.title ?? '';
            this.view = context.view ?? [];
            this.input = context.input ?? {}; // the form specification for the inputs included in the view
            this.entry = context.entry ?? {};
            this.faults = context.faults ?? [];

            // TODO: get this from server... and just prepare components based on the things in the 'view' array ... select the components, etc. then let the view render them all
            // const step = this.entry.step ?? 0;
            // const sequence = this.view ?? [];

            let mode = 'entry';
            if (this.view.length === 1 && this.view[0].type === 'confirm') {
                mode = 'confirm';
                this.confirm = { headline: this.view[0].headline ?? '', message: this.view[0].message ?? '' };
            }
            console.log(`render: mode ${mode}`);
            this.mode = mode;

            // auto-correct any issues in the form specification for input items in the view
            this.view.filter((item) => item.type === 'input').forEach((item) => {
                const input = this.input[item.key];

                // assign a vue ref id to each input with a prefix so they cannot conflict with any elements outside the rendered form
                input.ref ??= `input__${item.key}`;

                console.log(`render: input item ${item.key}: ${JSON.stringify(input)}`);
                // this.$set(this.entry, item.key, null); // start with a null value for every input (optional and required) so all inputs on page will be submitted to server -- THIS WAS COMMENTED OUT BECAUSE IT CLEARS OUT THE DATA THE SERVER SENDS BACK TO US THAT WAS ALREADY SUBMITTED; NEED TO KEEP TRACK OF CHANGES ANOTHER WAY
                if (input.type === 'select' && !Array.isArray(input.select?.choices)) {
                    console.error(`render: input item ${item.key} is type ${input.type} but doesnt have choices ${JSON.stringify(input)}`);
                    input.select ??= {};
                    input.select.choices ??= [];
                    this.$set(this.input, item.key, input);
                }
            });

            // const item = sequence[step] ?? {};
            // if (item.type === 'hr') {
            //     // TODO: horizontal separator, and go to next step
            // } else if (item.type === 'break') {
            //     // TODO: page break, and go to next step
            // } else if (item.type === 'input') {
            //     const input = this.input[item.key] ?? {};
            //     // TODO: render input: name, label, type (text/select/numeric/etc.), is_array, is_required, etc.
            //     console.log(`render: input: ${JSON.stringify(input)}`);
            // } else {
            //     console.log(`render: unknown type ${JSON.stringify(item.type)} in step ${step}`);
            //     // TODO: skip unknown type, go to next step
            //     return;
            // }

            this.activateFirstInput();
        },
        activateFirstInput() {
            if (!this.view) {
                console.log('activateFirstInput: view is not ready');
                return;
            }
            // auto-focus on first input
            const inputList = this.view.filter((item) => item.type === 'input') ?? [];
            console.log(`activateFirstInput: input list is ${JSON.stringify(inputList)}`);
            if (inputList.length > 0) {
                const firstInputKey = inputList[0].key;
                console.log(`activateFirstInput: first input key is ${JSON.stringify(firstInputKey)}`);
                // only try to activate the first element after form has been fully rendered
                this.$nextTick(() => {
                    setTimeout(() => { this.activate(firstInputKey); }, 1);
                });
            }
        },
        activate(key) {
            /*
            if (this.active === key) {
                console.log(`activate(${JSON.stringify(key)}): already activated`);
                return;
            }
            */
            const activeInput = this.input[key];
            if (typeof activeInput !== 'object' || activeInput === null) {
                console.log(`activate(${JSON.stringify(key)}): invalid input key`);
                return;
            }
            const ref = activeInput.ref;
            console.log(`activate: first input ref is ${JSON.stringify(ref)} with input type ${activeInput.type}`);
            // console.log(`render: this.$refs is ${JSON.stringify(this.$refs)}`);
            // when we use computed 'ref' values, vue puts them into an array even if they are unique
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // NOTE: see "formInputTypeChoices" in the app website for possible input types
                console.log(`activate: input ref element: ${JSON.stringify(Object.keys(inputRef))}`);
                // TODO: if the input type is textarea, then we need to use .querySelector('textarea')
                if (['text', 'email', 'url', 'numeric', 'currency'/* 'date', 'year', 'month', 'weekday', 'time', 'color', 'file', 'image' */].includes(activeInput.type)) {
                    // more than one way to do it:
                    // 1. inputRef.focus();
                    // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                    // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                    inputRef.focus();
                    // inputRef.$el.focus();
                    // const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                    // const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus();
                    this.active = key;
                    console.log(`activate: attempted to focus text input; active is now ${this.active}`);
                } else if (activeInput.type === 'textarea') {
                    console.log(`activate: auto-focus textarea field ${JSON.stringify(activeInput)}`);
                    // TODO if the textarea input 'type' is something else, update this, because currently we don't have a long-text input type so we're just assuming it will be called 'textarea' like the html element
                    // const inputElement = inputRef.$el.querySelector('textarea');
                    // inputElement.focus();
                    // setTimeout(() => { inputElement.focus(); }, 1);
                    inputRef.focus();
                    this.active = key;
                } else if (activeInput.type === 'select') {
                    console.log(`activate: auto-focus select field ${JSON.stringify(activeInput)}`);
                    // const inputElement = inputRef.$el.querySelector('select');
                    // inputElement.focus();
                    // setTimeout(() => { inputElement.focus(); }, 1);
                    inputRef.focus();
                    this.active = key;
                } else {
                    console.log(`activate: auto-focus not implemented for input type: ${JSON.stringify(activeInput.type)}`);
                    this.active = null;
                }
                // TODO: any other types like calendar, etc. should cause it to open
            }
        },
        nextStep() {
            // this is called when user hits the enter key
            // TODO: decide if we move to next form field, or submit the form to go to next page
            console.log(`nextStep, last active input was ${this.active}`);
            // find the position of the last active input
            const inputList = this.view.filter((item) => item.type === 'input') ?? [];
            const index = inputList.findIndex((item) => item.key === this.active);
            // check if the last active input was the last one on the page
            const lastInputIndex = inputList.length - 1;
            if (index === lastInputIndex) {
                // submit the form
                this.submitInput();
            } else {
                // TODO: validate the input immediately, if it's bad then show an error and stay on the current input (OPEN QUESTION if we really want it to work like that, it could be annoying that it doesn't move on like the user is asking);
                // TODO: we can actually submit each input to the server as we go along (OPEN QUESTION if we really want to do that, because sometimes inputs are validated together and if we only send one, server might reply with a fault about missing fields because user just hasn't gotten there yet, and we'll be showing uesless errors)
                // go to next input
                const nextInputKey = inputList[index + 1].key;
                this.activate(nextInputKey);
            }
        },
        async loadBrand() {
            return this.$store.dispatch('loadBrand', { alias: this.brandprofile });
        },
        async loadPalette() {
            return this.$store.dispatch('loadPalette', { alias: this.brandprofile, mode: 'light' });
        },
    },
    created() {
        this.$bus.$on('window:focus', () => {
            // restore focus to the last active control or to the first control
            if (this.active) {
                console.log('window:focus handler: with view resuming last');
                this.$nextTick(() => {
                    setTimeout(() => { this.activate(this.active); }, 1);
                });
            } else if (this.view) {
                console.log('window:focus handler: with view, acitvate first');
                this.activateFirstInput();
            } else {
                console.log('window:focus handler: no view, init?');
                this.init();
            }
        });
    },
    mounted() {
        this.init();
    },
};
</script>
